import React from 'react';
import {Link, Stack} from "@mui/material";

export const ContentBox = () => {

    return (<React.Fragment>
        <Stack spacing={2} marginTop={2}>
            <p>Hello! My name is Gareth and i am a developer and freelancer based in London.</p>
            <p><strong> Core Skills</strong></p>
            <p>ReactJS, Typescript, Node, OpenAI, PHP, API integrator</p>
            <p><strong> About Me</strong></p>
            <p>I’m Gareth, a seasoned engineer from London with a keen interest in OpenAI and Blockchain
                technology.With extensive experience in project lifecycles, I specialise in API development and
                technology
                consulting. I am now seeking new challenges in the UK, Europe, and globally</p>
            <p><strong> Capture my interest</strong></p>
            <p>I love Mentoring, leading a team, coding, best practices, efficiency & performance. I thrive in
                creative, flexible work environments. I love innovation and being part of a team that makes
                wonderful things.</p>
            <p><strong> My 1-hour Test portfolio piece is here (Written in Javascript)</strong></p>
            <Link target={'_blank'}
                  href={'https://clearandfizzy-todo.netlify.app/'}>https://clearandfizzy-todo.netlify.app/</Link>
            <p><strong> The source code is here</strong></p>
            <Link target={'_blank'}
                  href={'https://bitbucket.org/clearandfizzy/test-frontend-developer/src/master/'}>https://bitbucket.org/clearandfizzy/test-frontend-developer/src/master/</Link>
        </Stack>

    </React.Fragment>);
}
